import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
// import { UserOutlined, LockOutlined } from 'react-bootstrap';
import {
    app, getAuth, setSessionID, signInWithEmailAndPassword
} from "../context/firebaseConfig";
import { useTranslation } from 'react-i18next';

import { useLocation, useNavigate } from "react-router-dom";

import '../assets/css/login.css'

const Login = (props) => {
    const nav = useNavigate();
    const loc = useLocation();
    const { t } = useTranslation();
    const { Footer } = Container;
    const [error, seterror] = useState('')
    const [auth, setAuth] = useState();

    useEffect(()=> {
        setAuth(getAuth(app));
    }, []);

    const withEmail = async e => {
        //e.preventDefault();
        const { user, password } = e;
        await signInWithEmailAndPassword(auth, user, password)
            .then(async result => {
                /* let to = (window.history.state && window.history.state.idx > 0) ? -1 : '/banners';
                if(loc.pathname === '/') to = '/banners'; */
                // Actualizar el sessionID de la base de datos
                await setSessionID({user: auth?.currentUser?.email, sessionID: +new Date})
                return nav('/banners'); // nav(to);
            })
            .catch(error => {
                seterror(t(error.code))
                console.log(error);                
            });
    };

    return (
        <Container style={{ height: "90vh" }}>
            <Row justify='center' align='middle' style={{ height: "80vh" }}>
                <Col span={12} style={{ "textAlign": "center"}}>
                    <img src={`/assets/img/Mal3kith.jpg`} alt="" />
                </Col>
                <Col span={12} style={{ 
                    display: "flex", 
                    "justifyContent": "center"
                }}>
                    <div className='d-flex bg-light align-items-center text-center'
                        style={{
                            padding: 24,
                            width: 400
                        }}
                        >
                        {(
                            <Form className="login-form" onFinish={withEmail}>
                                <Form.Label>
                                    <h1 className='text-primary'>{t('LOGIN')}</h1>
                                </Form.Label>
                                {error? <Form.Label>{error}</Form.Label>:null}
                                <Form.Group
                                    name="user"
                                    rules={[{ required: true, message: t('ERRMSG_FIELD_REQUIRED') }]}
                                    >
                                    <Form.Control type="text" 
                                        // prefix={<UserOutlined  style={{ color: "rgba(0,0,0,.25)" }} /> } 
                                        placeholder={t('USERNAME')} 
                                        />
                                </Form.Group>
                                <Form.Group
                                    name="password"
                                    rules={[{ required: true, message: t('ERRMSG_FIELD_REQUIRED') }]}
                                    >
                                    <Form.Control
                                        // prefix={<LockOutlined style={{color: "rgba(0,0,0,.25)"}}/>}
                                        type="password"
                                        placeholder={t('PASSWORD')}
                                        />
                                </Form.Group>
                                <Form.Group>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                        style={{ marginRight: 10 }}
                                        >
                                        {t('BUTTON_LOGIN')}
                                    </Button>
                                </Form.Group>
                            </Form>
                        )}
                    </div>
                </Col>
            </Row>
                        {/* 
            <Footer className="fixed-bottom d-none d-sm-block" style={{ textAlign: "center" }}>
                Powered by <a href='https://kaps.es'> Kaps Management Group</a>
            </Footer>
            */}
        </Container>
    );
};
export default Login;