import './App.css';
import Views from './Views'
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <Views />
  )
}

export default App;