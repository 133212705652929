
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import {app, getAuth } from "./firebaseConfig";
import Loading from '../components/Loading'

const Auth = React.createContext({ name: '', user: null });

const AuthContext = ({ children }) => {
    // const nav = useNavigate();
    const [user, setUsuario] = useState(null);
    const [showChild, setShowChild] = useState(false);
    // const [sessionID, setSesID] = useState(0);

    useEffect(() => {
      const au = getAuth(app);
      au.onAuthStateChanged(async (user) =>{
          const cus = au.currentUser;
          const hayUser = cus != null
          if(hayUser) {
              setUsuario(user);
          } else {
              setUsuario(null)
          }
          setShowChild(true);
      });
    }, []);

    if (!showChild) {
        return <Loading/>;
    } else {
        return (
            <Auth.Provider value={{ user }} key={Date.now()}>
                {children}
            </Auth.Provider>
        );
    }    
};
    function useFirebaseAuth() {
      const context = React.useContext(Auth);
      if (context === undefined) {
        throw new Error(
          "useFirebaseAuth must be used within a FirebaseAuthProvider"
          );
        }
        return context.user;
      }
      
  export { Auth, AuthContext, useFirebaseAuth };