import { Spinner, Col, Row, Container } from 'react-bootstrap';

const Loading = () => {
    return (
        <Container type='flex' style={{position: 'absolute', top: 0, left: 0, zIndex: 100, width: '100%'}}>
            <Row type="flex" style={{width:'100%', height: '100vh',alignItems: 'center', textAlign: 'center', backgroundColor: 'RGBA(0,0,0,0.3)'}}>
                <Col md={24}>
                    <Spinner animation="border" variant="primary" />
                </Col>
            </Row>
        </Container>
    )
}
           
export default Loading;