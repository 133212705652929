import { Route, Routes } from 'react-router-dom'

import Login from './views/Login'

// import ProtectedRoute from './ProtectedRoute'
import NotFound from './views/NotFound'

import { AuthContext } from './context/AuthContext'

const Views = () => {
    return (
        <AuthContext>
            <Routes>
                <Route path='/' element={<Login/>} key='toute_5'/>
                    {/* <Route element={<ProtectedRoute/>} key='toute_6'>
                        <Route path='/banners' element={<Banners/>} key='toute_7'/>
                    </Route> */}
                <Route path='*' element={<NotFound />} key='toute_404'/>
            </Routes>
        </AuthContext>
    )
}

export default Views;