import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { getFirestore, collection, orderBy, limit, startAfter, query, getDocs, onSnapshot, doc, getDoc, updateDoc, addDoc, connectFirestoreEmulator } from 'firebase/firestore'
// import { getRemoteConfig, getValue, fetchAndActivate, setValue } from "firebase/remote-config";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import axios from 'axios'

const app = initializeApp({
    apiKey: "AIzaSyAf70kgwoIrg-vGcTHokdPDXPjdVdqcnXg",
    authDomain: "mal3kith-kab.firebaseapp.com",
    databaseURL: "https://mal3kith-kab.firebaseio.com",
    projectId: "mal3kith-kab",
    storageBucket: "mal3kith-kab.appspot.com",
    messagingSenderId: "173447689231",
    appId: "1:173447689231:web:c18c891ebcdc660d431f7a"
});

const RCNAME = '';
const RCURL = "";

const logOut = async () => {
    const au = getAuth(app);
    await signOut(au);
}

const getData = async (params) => {
    const db = getFirestore(app);
    const {coll, lim, start, order} = params;
/*     const q = start ? query(collection(db, coll), orderBy(order), startAt(start), limit(lim)) : 
                      query(collection(db, coll), orderBy(order), limit(lim)); */
    const q = query(collection(db, coll), orderBy(order), startAfter(start), limit(lim))
    const snapshot = await getDocs(q);  
//console.log(snapshot.docs)    
    return snapshot;
}

const getSessionID = async (params) => {
    const { user } = params;
    const db = getFirestore(app);
    const ref = doc( db, `users/`, user);
    /* await updateDoc(ref, {
        "sessionIDs": deleteField()
    }); */
    const ref2 = await getDoc(ref);
    let ret = ref2.data();
    return ret?.sessionIDs ? ret?.sessionIDs[0] : 0;
}

const setSessionID = async (params) => {
    const { user, sessionID } = params;
    const db = getFirestore(app);
    const ref = doc( db, `users/`, user);
    /* await updateDoc(ref, {
        "sessionIDs": deleteField()
    }); */
    await updateDoc(ref, {
        "sessionIDs": [sessionID]
    })
    window.localStorage.setItem('sessionID', sessionID);
}
/* 
const getRCValue = async (v) => {
    let remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    await fetchAndActivate(remoteConfig);
    const val = getValue(remoteConfig, v || RCNAME) || [];
    return val;
}

const setRCValue = async (v) => {
    const au = getAuth(app);
    const token = au.currentUser.accessToken;

    const headers = {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
    }
    const data = {name: RCNAME, "value": v};
    try {
    const ret = await axios({
        method: "put",
        url: RCURL,
        data: data, 
        headers: headers
    });
    } catch(e) { console.log(e)}
    return;
}
 */
const addDocument = async (coll, data) => {
    let ret = null;
    try {
        const db = getFirestore(app);
        /* if(window?.document?.location?.hostname == 'localhost') {
            try {
                connectFirestoreEmulator(db, 'localhost', 8080)
            } catch (error) {
                console.log('No se puede conetactr al emulador de firestore ojo!!!!')
            }
        } */
        ret = await addDoc(collection(db, coll), data);
    } catch(e) {
        console.log(e);
    }
    return ret;
}

export { 
    app,
    initializeApp,
    signInWithEmailAndPassword,
    getFirestore,
    getAuth,
    logOut,
    getData,
    setSessionID,
    getSessionID,
    // getRCValue,
    // setRCValue,
    addDocument
}